<template>
	<div class="places-balloon">
		<div class="places-balloon__logo">
			<svgicon :name="'logo-white'"/>
		</div>
		<div class="places-balloon__text size2">
			<h5 class="places-balloon__name color-golden">{{ item.NAME }}</h5>
			<div class="places-balloon__address">{{ item.ADDRESS }}</div>
			<div v-if="item.PHONE" class="places-balloon__line"></div>
			<div v-if="item.PHONE" class="places-balloon__phone">{{ item.PHONE }}</div>
			<div v-if="item.SCHEDULE" class="places-balloon__line"></div>
			<div v-if="item.SCHEDULE" class="places-balloon__phone">{{ item.SCHEDULE }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "places-balloon",
	props: {
		item: Object,
	}
}
</script>

<style lang="scss">
$balloon-breakpoint: 458px;

.places-balloon {
	color: $default-text-color;
	padding: 8px 4px 8px 4px;
	display: flex;

	@include up($balloon-breakpoint) {
		display: block;
		padding: 20px 0 20px 20px;
		width: 270px;
	}



	&__logo {
		width: 40px;
		height: 40px;

		@include up($balloon-breakpoint) {
			margin-bottom: 16px;
			width: 52px;
			height: 52px;
		}

		svg {
			display: block;
			width: 40px;
			height: 40px;
			fill: $primary-500;

			@include up($balloon-breakpoint) {
				width: 52px;
				height: 52px;
			}
		}
	}

	&__name {
		margin-bottom: 4px;
		@include up($balloon-breakpoint) {
			margin-bottom: 8px;
		}
	}

	&__line {
		padding-top: 8px;
		border-bottom: 1px solid $primary-100;
		@include up($balloon-breakpoint) {
			padding-top: 20px;
		}
	}

	&__phone {
		padding-top: 8px;
		@include up($balloon-breakpoint) {
			padding-top: 20px;
		}
	}

	&__text {
		margin-left: 16px;
		@include up($balloon-breakpoint) {
			margin-left: 0;
		}
	}
}
</style>